<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card
              no-body
              class="p-4"
            >
              <b-card-body>
                <b-form
                  novalidate
                  @submit.prevent="onSubmit"
                >
                  <h1>Вход</h1>
                  <p class="text-muted">
                    Войдите в свой аккаунт
                  </p>
                  <b-form-group
                    id="usernameInputGroup1"
                    label="Имя пользователя (email)"
                    label-for="username"
                  >
                    <b-form-input
                      id="username"
                      v-model.trim="$v.form.username.$model"
                      type="text"
                      name="username"
                      :state="chkState('username')"
                      aria-describedby="input1LiveFeedback1"
                      placeholder="Имя пользователя (email)"
                      autocomplete="username, email"
                      autofocus
                    />
                    <b-form-invalid-feedback id="input1LiveFeedback1">
                      Это поле обязательно для заполнения
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    id="passInputGroup2"
                    label="Пароль"
                    label-for="raw_password"
                  >
                    <b-form-input
                      id="raw_password"
                      v-model.trim="$v.form.rawPassword.$model"
                      type="password"
                      name="raw_password"
                      :state="chkState('rawPassword')"
                      aria-describedby="input1LiveFeedback2"
                      placeholder="Пароль"
                    />
                    <b-form-invalid-feedback id="input1LiveFeedback2">
                      Это поле обязательно для заполнения
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-row>
                    <b-col cols="6">
                      <b-button
                        type="submit"
                        variant="primary"
                        :disabled="$v.form.$invalid || loading"
                        :class="{'loading' : loading}"
                      >
                        Вход
                      </b-button>
                    </b-col>

                    <b-col
                      cols="6"
                      class="text-right"
                    >
                      <router-link
                        to="/forgot-password"
                        tag="button"
                        class="btn px-0 btn-link"
                      >
                        Забыли пароль?
                      </router-link>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
            <b-card
              no-body
              class="text-white bg-primary py-5 d-md-down-none"
              style="width:44%"
            >
              <!--b-card-body class="text-center">
                <div>
                  <h2>Регистрация</h2>
                  <p>Если у Вас нет аккаунта, пройдите регистрацию</p>
                  <router-link
                    to="/registration"
                    tag="button"
                    class="btn active mt-3 btn-primary"
                  >
                    Зарегистрироваться
                  </router-link>
                </div>
              </b-card-body-->
              <b-card-body>
                <div>
                  <h2>Контакты</h2>
                  <p>
                    Поддержка пользователей по<br>
                    телефону: <a
                      href="tel:849968111093999"
                      class="text-white"
                    >(8-499-681-1109 доб. 3999)</a><br>
                    email: <a
                      href="mailto:etp_info@pecom.ru"
                      class="text-white"
                    >etp_info@pecom.ru</a>
                  </p>
                </div>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import VueRecaptcha from 'vue-recaptcha';

const formShape = {
  username: '',
  rawPassword: '',
};

export default {
  name: 'Login',
  components: {
    VueRecaptcha,
  },
  mixins: [validationMixin],
  data() {
    return {
      form: Object.assign({}, formShape),
      feedBack: 'secondary',
      sitekey: 'ВАШ SITE KEY',
      loading: false,
    };
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
  validations: {
    form: {
      username: {
        required,
      },
      rawPassword: {
        required,
      },
    },
  },
  methods: {
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        await this.$store.dispatch('signIn', this.form);
        this.loading = false;
      }
    },
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else if (field.$invalid) {
        return false;
      } else {
        return true;
      }
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
